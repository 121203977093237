<template>
  <div>
    <b-card-code title="Add Setting" :before-change="validateForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <div>
                <b-row v-for="(item, index) in newSettingData" :key="index">
                  <!-- type -->
                  <b-col md="6">
                    <b-form-group label="Type Group" label-for="type">
                      <validation-provider
                        #default="{ errors }"
                        name="type"
                        rules="required"
                      >
                        <v-select
                          v-model="item.type"
                          :options="settingTypeOptions"
                          @input="updateSeletedSettingType($event, index)"
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- attribute -->
                  <b-col md="6">
                    <b-form-group label="Attribute Name" label-for="attribute">
                      <validation-provider
                        #default="{ errors }"
                        name="attribute"
                        rules="required"
                      >
                        <b-form-input
                          v-model="item.attribute"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- data_type -->
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="data_type"
                      rules="required"
                    >
                      <b-form-group
                        label="DataType"
                        label-for="data_type"
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                        :disabled=" item.type == null"
                          v-model="item.data_type"
                          :options="
                            item.type == 'General'
                              ? settingGeneralDataTypeOptions
                              : item.type == 'Slider'
                              ? settingSliderTypeOptions
                              : item.type == 'Banners'
                              ? settingBannerTypeOptions
                              : item.type == 'Static Pages'
                              ? settingStaticPagesDataTypeOptions
                              : []
                          "
                          :selectable="
                            (option) => !option.value.includes('select_value')
                          "
                          @input="updateSeletedDataType($event, index)"
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="12"> </b-col>
                  <!-- value String -->
                  <b-col md="6" v-if="item.data_type === 'string'">
                    <b-form-group label="Value" label-for="value_string">
                      <validation-provider
                        #default="{ errors }"
                        name="value_string"
                        rules="required"
                      >
                        <b-form-input
                          v-model="item.value"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12"> </b-col>
                  <!-- value Url -->
                  <b-col md="6" v-if="item.data_type === 'url'">
                    <b-form-group label="Value" label-for="value_url">
                      <validation-provider
                        #default="{ errors }"
                        name="value_url"
                        rules="required|url"
                      >
                        <b-form-input
                          v-model="item.value"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12"> </b-col>
                  <!-- value Boolean -->
                  <b-col md="6" v-if="item.data_type === 'boolean'">
                    <b-form-group label="Value" label-for="value_boolean">
                      <validation-provider
                        #default="{ errors }"
                        name="value_boolean"
                        rules="required"
                      >
                        <v-select
                          id="item.attribute + index"
                          v-model="item.value"
                          :clearable="false"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="boolOptions"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- value text -->
                  <b-col md="12" v-if="item.data_type === 'text'">
                    <b-row
                      v-for="(
                        staticPageLocale, staticIndexLocale
                      ) in item.staticPagesLocaleArray"
                      :key="staticIndexLocale"
                    >
                      <b-col md="12">
                        <app-collapse
                          id="faq-payment-qna"
                          type="margin"
                          class="mt-0 mb-0"
                        >
                          <!-- Index Locale For Media Array -->
                          <app-collapse-item
                            :title="
                              staticPageLocale.locale == 'ar'
                                ? 'Text Data Arabic'
                                : 'Text Data English'
                            "
                            :key="item.type + index"
                          >
                          <b-form-group  label-for="value_text">
                            <validation-provider
                              #default="{ errors }"
                              name="value_text"
                              rules="required"
                            >
                              <vue-editor
                                :editor-toolbar="customToolbar"
                                id="value"
                                @imageAdded="handleImageAdded"
                                v-model="staticPageLocale.text"
                                :state="errors.length > 0 ? false : null"
                                rows="20"
                              ></vue-editor>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                          </app-collapse-item>

                        </app-collapse>
                      </b-col>
                    </b-row>
                  </b-col>

                  <!-- value float -->
                  <b-col md="6" v-if="item.data_type === 'float'">
                    <b-form-group label="Value" label-for="value_float">
                      <validation-provider
                        #default="{ errors }"
                        name="value_float"
                        rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                      >
                        <b-form-input
                          v-model="item.value"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- value file -->
                  <b-col md="6" v-if="item.data_type === 'file'">
                    <b-form-group label="File" label-for="value_file">
                      <validation-provider
                        #default="{ errors }"
                        name="value_file"
                        rules="required"
                      >
                        <b-form-file
                          v-model="item.value"
                          :state="errors.length > 0 ? false : null"
                          accept="application/pdf"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12"> </b-col>

                  <!-- value image -->
                  <b-col md="6" v-if="item.data_type === 'image'">
                    <b-form-group label="Image" label-for="value_image">
                      <validation-provider
                        #default="{ errors }"
                        name="value_image"
                        rules="required"
                      >
                        <b-form-file
                          v-model="item.value"
                          :state="errors.length > 0 ? false : null"
                          accept="image/*"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12"> </b-col>

                  <!-- value email -->
                  <b-col md="6" v-if="item.data_type === 'email'">
                    <b-form-group label="Value" label-for="value_email">
                      <validation-provider
                        #default="{ errors }"
                        name="value_email"
                        rules="required|email"
                      >
                        <b-form-input
                          v-model="item.value"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- value media_array -->
                  <b-col md="12">
                    <div v-if="item.data_type === 'media_array'">
                      <hr />

                      <!--  Locale For Media Array -->
                      <b-row
                        v-for="(
                          mediaLocaleArray, mediaIndexLocale
                        ) in item.mediaLocaleArrayData"
                        :key="mediaIndexLocale"
                      >
                        <b-col md="12">
                          <app-collapse
                            id="faq-payment-qna"
                            type="margin"
                            class="mt-0 mb-0"
                          >
                            <!-- Index Locale For Media Array -->
                            <app-collapse-item
                              :title="
                                mediaLocaleArray.locale == 'ar'
                                  ? 'Media Data Arabic'
                                  : 'Media Data English'
                              "
                              style="color: coral;"
                              :key="item.type + index"
                            >
                              <!-- Size Array -->
                              <b-row
                                v-for="(
                                  arrayMediaSize, arrayIndexSize
                                ) in mediaLocaleArray.sizeMediaArray"
                                :key="arrayIndexSize"
                              >
                                <b-col md="12">
                                  <!-- Images Array -->
                                  <app-collapse
                                    id="faq-payment-qna"
                                    type="margin"
                                    class="mt-0 mb-0"
                                  >
                                    <!-- Index Locale For Media Array -->
                                    <app-collapse-item
                                      :title="arrayMediaSize.size + '  Size'"
                                      :key="
                                        arrayMediaSize.size + arrayIndexSize
                                      "
                                      style="color: #6e6b7b;"
                                    >
                                      <!-- draggable -->
                                      <draggable
                                        :list="arrayMediaSize.imageArray"
                                        tag="ul"
                                        group="media"
                                        class="list-group list-group-flush cursor-move"
                                      >
                                        <b-row
                                          v-for="(
                                            arrayMedia, arrayIndex
                                          ) in arrayMediaSize.imageArray"
                                          :key="arrayIndex"
                                        >
                                          <b-col md="6">
                                            <b-row>
                                              <b-col
                                                md="12"
                                                style="
                                                  text-align: center;
                                                  align-self: center;
                                                  text-decoration: underline;
                                                "
                                              >
                                                <h4>
                                                  Image {{ arrayIndex + 1 }}
                                                </h4>
                                              </b-col>

                                              <b-col md="12">
                                                <b-form-group
                                                  label="Image"
                                                  label-for="image_single_media"
                                                >
                                                  <validation-provider
                                                    #default="{ errors }"
                                                    name="image_single_media"
                                                    rules="required"
                                                  >
                                                    <b-form-file
                                                      v-model="arrayMedia.image"
                                                      @change="
                                                        ChangeMediaImageValue(
                                                          $event,
                                                          index,
                                                          mediaIndexLocale,
                                                          arrayIndexSize,
                                                          arrayIndex
                                                        )
                                                      "
                                                      :state="
                                                        errors.length > 0
                                                          ? false
                                                          : null
                                                      "
                                                      accept="image/*"
                                                    />
                                                    <small
                                                      class="text-danger"
                                                      >{{ errors[0] }}</small
                                                    >
                                                  </validation-provider>
                                                </b-form-group>
                                              </b-col>

                                              <b-col md="12">
                                                <b-form-group
                                                  label="Alt"
                                                  label-for="alt_single_media"
                                                >
                                                  <validation-provider
                                                    #default="{ errors }"
                                                    name="alt_single_media"
                                                    rules="required"
                                                  >
                                                    <b-form-input
                                                      v-model="arrayMedia.alt"
                                                      :state="
                                                        errors.length > 0
                                                          ? false
                                                          : null
                                                      "
                                                    />
                                                    <small
                                                      class="text-danger"
                                                      >{{ errors[0] }}</small
                                                    >
                                                  </validation-provider>
                                                </b-form-group>
                                              </b-col>

                                              <b-col md="12">
                                                <b-form-group
                                                  label="Redirect Link"
                                                  label-for="link_single_media"
                                                >
                                                  <b-form-input
                                                    v-model="arrayMedia.link"
                                                  />
                                                </b-form-group>
                                              </b-col>

                                              <b-col
                                                md="12"
                                                class="mb-50"
                                                style="
                                                  text-align: center;
                                                  align-self: center;
                                                "
                                              >
                                                <b-button
                                                  v-ripple.400="
                                                    'rgba(234, 84, 85, 0.15)'
                                                  "
                                                  v-show="arrayIndex != 0"
                                                  variant="outline-danger"
                                                  class="mt-0 mt-md-2"
                                                  @click="
                                                    removeMediaFromArray(
                                                      $event,
                                                      index,
                                                      mediaIndexLocale,
                                                      arrayIndexSize,
                                                      arrayIndex
                                                    )
                                                  "
                                                >
                                                  <feather-icon
                                                    icon="XIcon"
                                                    class="mr-25"
                                                  />
                                                  <span>Remove</span>
                                                </b-button>
                                              </b-col>
                                            </b-row>
                                          </b-col>
                                          <b-col
                                            cols="6"
                                            v-if="
                                              arrayMedia.imagePreview != null
                                            "
                                          >
                                            <div></div>

                                            <b-img
                                              class="w-auto"
                                              style="max-width: 75%"
                                              :src="arrayMedia.imagePreview"
                                            />
                                          </b-col>

                                          <b-col cols="12"> <hr /> </b-col>
                                        </b-row>
                                      </draggable>
                                      <!-- add new button -->
                                      <b-col sm="2">
                                        <b-button
                                          v-ripple.400="
                                            'rgba(255, 255, 255, 0.15)'
                                          "
                                          variant="outline-warning"
                                          block
                                          @click="
                                            addNewMediaToArray(
                                              $event,
                                              index,
                                              mediaIndexLocale,
                                              arrayIndexSize
                                            )
                                          "
                                        >
                                          <feather-icon
                                            icon="PlusIcon"
                                            class="mr-25"
                                          />
                                          <span>Add New Media</span>
                                        </b-button>
                                      </b-col>
                                      <b-col cols="12">
                                        <hr />
                                      </b-col>
                                    </app-collapse-item>
                                  </app-collapse>
                                </b-col>
                              </b-row>
                            </app-collapse-item>
                          </app-collapse>
                        </b-col>
                      </b-row>

                    </div>
                  </b-col>

                  <!-- value single_media -->
                  <b-col md="12">
                    <div v-if="item.data_type === 'single_media'">
                      <hr />
                      <!-- <b color="primary">Media Data</b>
                      <hr /> -->

                      <b-row
                        v-for="(
                          mediaLocale, indexLocale
                        ) in item.singleMediaWithLocale"
                        :key="indexLocale"
                      >
                        <b-col md="12">
                          <app-collapse
                            id="faq-payment-qna"
                            type="margin"
                            class="mt-0 mb-0"
                          >
                            <!-- Store Pickup Request  For Order -->
                            <app-collapse-item
                              :title="
                                mediaLocale.locale == 'ar'
                                  ? 'Media Data Arabic'
                                  : 'Media Data English'
                              "
                              :key="item.type + indexLocale"
                              style="color: coral;"
                            >
                              <b-row
                                v-for="(
                                  singleMediaSize, indexSize
                                ) in mediaLocale.single_media_array"
                                :key="indexSize"
                              >
                                <b-col md="6">
                                  <b-row>
                                    <b-col
                                      md="12"
                                      style="
                                        text-align: center;
                                        align-self: center;
                                        text-decoration: underline;color: #6e6b7b;
                                      "
                                    >
                                      <h4>{{ singleMediaSize.size }} Size</h4>
                                    </b-col>

                                    <b-col md="12">
                                      <b-form-group
                                        label="Image"
                                        label-for="image_single_media"
                                      >
                                        <validation-provider
                                          #default="{ errors }"
                                          name="image_single_media"
                                          rules="required"
                                        >
                                          <b-form-file
                                            v-model="singleMediaSize.image"
                                            @change="
                                              ChangeSingleImageValue(
                                                $event,
                                                index,
                                                indexLocale,
                                                indexSize
                                              )
                                            "
                                            :state="
                                              errors.length > 0 ? false : null
                                            "
                                            accept="image/*"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col>

                                    <b-col md="12">
                                      <b-form-group
                                        label="Alt"
                                        label-for="alt_single_media"
                                      >
                                        <validation-provider
                                          #default="{ errors }"
                                          name="alt_single_media"
                                          rules="required"
                                        >
                                          <b-form-input
                                            v-model="singleMediaSize.alt"
                                            :state="
                                              errors.length > 0 ? false : null
                                            "
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col>

                                    <b-col md="12">
                                      <b-form-group
                                        label="Redirect Link"
                                        label-for="link_single_media"
                                      >
                                        <b-form-input
                                          v-model="singleMediaSize.link"
                                        />
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                </b-col>
                                <b-col
                                  cols="6"
                                  v-if="singleMediaSize.imagePreview != null"
                                >
                                  <div></div>

                                  <b-img
                                    class="w-auto"
                                    style="max-width: 75%"
                                    :src="singleMediaSize.imagePreview"
                                  />
                                </b-col>

                                <b-col cols="12"> <hr /> </b-col>
                              </b-row>
                            </app-collapse-item>
                          </app-collapse>
                        </b-col>
                      </b-row>

                    </div>
                  </b-col>

                  <b-col cols="12"> </b-col>
                  <!-- Remove Button -->
                  <b-col lg="2" md="3" class="mb-50">
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      v-show="index != 0"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeSetting(index)"
                    >
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>Remove</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12" v-if="index+1 != newSettingData.length">
                    <hr
                      class="w-100 border-gray my-1"
                      style="border-top: 5px solid rgb(244 221 206);" />
                  </b-col>
                </b-row>
              </div>
            </b-col>

            <!-- add new button -->
            <b-col sm="2" >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                block
                @click="addNewSetting"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add New</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validateForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="$store.state.Setting.showDismissibleAlert"
      @dismissed="$store.state.Setting.showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul
          v-for="(values, index) in $store.state.Setting.errors_back"
          :key="index"
        >
          <li v-for="(value, index) in values" :key="index">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import draggable from 'vuedraggable'
import { VueEditor } from 'vue2-editor'
import BCardCode from '@core/components/b-card-code'

import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@validations'
// import axios from '@/libs/axios'

export default {
  components: {
    BCardCode,
    VueEditor,
    draggable,
    AppCollapse,
    AppCollapseItem,
  },
  mixins: [heightTransition],
  data() {
    return {
      // errors_back: '',
      // showDismissibleAlert: false,
      required,
    }
  },
  computed: {
    newSettingData() {
      return this.$store.state.Setting.newSettingData
    },
    settingGeneralDataTypeOptions() {
      return this.$store.state.Setting.settingGeneralDataTypeOptions
    },
    settingBannerTypeOptions() {
      return this.$store.state.Setting.settingBannerTypeOptions
    },
    settingSliderTypeOptions() {
      return this.$store.state.Setting.settingSliderTypeOptions
    },
    settingStaticPagesDataTypeOptions() {
      return this.$store.state.Setting.settingStaticPagesDataTypeOptions
    },
    boolOptions() {
      return this.$store.state.Setting.boolOptions
    },
    settingTypeOptions() {
      return this.$store.state.Setting.settingTypeOptions
    },
    customToolbar() {
      return this.$store.state.Setting.customToolbar
    },
    // errorsBack(){
    //   return this.$store.state.Setting.errors_back;

    // },
    // showDisnissaibleAlert(){
    //   return this.$store.state.Setting.showDismissibleAlert;

    // }
  },
  methods: {
    ChangeSingleImageValue(e, index, indexLocale, indexSize) {
      const image = e.target.files[0]
      if (e != null) {
        this.newSettingData[index].singleMediaWithLocale[
          indexLocale
        ].single_media_array[indexSize].imagePreview =
          URL.createObjectURL(image)

        var fileReader = new FileReader()
        fileReader.readAsDataURL(image)
      }
    },
    ChangeMediaImageValue(e, index, indexLocale, indexSize, indexArray) {
      const image = e.target.files[0]
      if (e != null) {
        this.newSettingData[index].mediaLocaleArrayData[
          indexLocale
        ].sizeMediaArray[indexSize].imageArray[indexArray].imagePreview =
          URL.createObjectURL(image)

        var fileReader = new FileReader()
        fileReader.readAsDataURL(image)
      }
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      this.$store.commit('Setting/storeImageEditor', {
        file,
        Editor,
        cursorLocation,
        resetUploader,
      })
    },
    // update the select data_type value
    updateSeletedDataType(e, index) {
      // console.log('updateSeletedDataType')
      this.$store.commit('Setting/updateSettingSelectedDataType', { e, index })
      // this.newSettingData[index].data_type = e.value
    },
    updateSeletedSettingType(e, index) {
      this.$store.commit('Setting/updateSettingSelectedType', { e, index })
      // this.newSettingData[index].data_type = e.value
    },
    // add new setting data
    addNewSetting() {
      this.$store.commit('Setting/addNewSetting')
    },
    addNewMediaToArray(e, index, indexLocale, indexSize, indexArray) {
      this.$store.commit('Setting/addNewImageToMediaArrayWithLocale', {
        index,
        indexLocale,
        indexSize,
      })
    },
    // remove setting data
    removeSetting(index) {
      if (this.newSettingData.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.$store.commit('Setting/removeSetting', index)
      }
    },
    // remove media from array
    removeMediaFromArray(e, index, indexLocale, arrayIndexSize, arrayIndex) {
      if (
        this.newSettingData[index].mediaLocaleArrayData[indexLocale]
          .sizeMediaArray[arrayIndexSize].length <= 1
      ) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.$store.commit('Setting/removeImageFromMediaArrayWithLocale', {
          index,
          indexLocale,
          arrayIndexSize,
          arrayIndex,
        })
      }
    },
    // submit category API
    async validateForm() {
      await this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.createSetting()
        }
      })
    },

    async createSetting() {
      await this.$store.dispatch('Setting/createSetting')
      if (this.$store.state.Setting.showDismissibleAlert == false) {
        this.$swal({
          position: 'center',
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500,
        })

        this.$router.push({
          path: '/settings/index',
        })
      } else {
        this.$swal({
          position: 'center',
          icon: 'error',
          title: 'Error!',
          showConfirmButton: false,
          timer: 1500,
        })
      }
    },

    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    // basic
  },

  beforeDestroy() {
    this.$store.commit('Setting/resetNewSettingsData')
  },
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped></style>
